<template>
  <!-- 委托管理页面 -->
  <div>
    <!-- 顶部选择框 -->
    <div style="padding: 30px">
      <el-radio-group v-model="form.type" @change="storeage">
        <el-radio-button label="1">买入委托记录</el-radio-button>
        <el-radio-button label="2">卖出委托记录</el-radio-button>
      </el-radio-group>
    </div>
    <!-- 顶部查询 -->
    <div class="form">
      <div class="formFtest">
        <!-- 按时间查询 -->
        <div style="">
          <span>按时间查询:</span>
          <div>
            <el-date-picker
              style="width: 300px"
              v-model="value"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-DD"
              @change="pickerChange"
            >
            </el-date-picker>
          </div>
        </div>
        <!-- 手机号查询 -->
        <div>
          <span>按Uid查询</span>
          <div>
            <el-input v-model="form.userId" placeholder="请输入Uid" style="width: 150px; margin-right: 10px"></el-input>
          </div>
        </div>
        <!-- 按数字通证名称查询按数字通证名称查询 -->
        <!-- <div>
          <span>按订单号查询</span>
          <div style="">
            <el-input
              prefix-icon="el-icon-star-off"
              v-model="form.turnsOutAddress"
              placeholder="请输入订单号"
              style="width: 150px; margin-right: 10px"
            ></el-input>
          </div>
        </div> -->
        <!-- 按状态查询 -->
        <!-- <div>
          <span>按状态查询</span>
          <div style="">
            <el-select
              v-model="value"
              placeholder="请选择"
              style="width: 150px; margin-right: 10px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div> -->
        <!-- 按类型查询 -->
        <div>
          <span>按状态查询</span>
          <div style="flex: 1">
            <el-select v-model="form.finish" placeholder="请选择" style="width: 150px; margin-right: 10px">
              <el-option v-for="item in finish" :key="item.value" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </div>
        </div>
        <!-- 请选择交易数字通证 -->
        <div>
          <span>请选择交易数字通证</span>
          <div>
            <el-select v-model="form.certificateId" placeholder="请选择" style="width: 200px">
              <el-option v-for="item in selectBox" :key="item.value" :label="item.tokenName" :value="item.certificateId"> </el-option>
            </el-select>
          </div>
        </div>
        <el-button type="primary" icon="el-icon-search" style="height: 40px; margin-top: 40px" @click="onSearch">搜索</el-button>
        <el-button type="primary" icon="el-icon-search" @click="exportData" style="height: 40px; margin-top: 40px">生成表格</el-button>
        <download-excel v-if="derivedForm" class="export" :data="derivedForm" :fields="jsonFields" type="xls" name="委托管理表.xls">
          下载表格
        </download-excel>
      </div>
    </div>
    <!-- 表格 -->
    <div style="margin: 30px 0">
      <div class="statistical">
        委托买入/卖出剩余数量统计:
        <span> 买入金额:{{ statistical.buyAmount }}</span>
        <span>买入数量:{{ statistical.buyNum }}</span
        ><span> 卖出金额:{{ statistical.saleAmount }}</span>
        <span>卖出数量:{{ statistical.saleNum }}</span>
      </div>
    </div>

    <!-- 表格 -->
    <!-- 表格 -->
    <div>
      <el-table :data="data" border="">
        <el-table-column prop="userId" label="UID"> </el-table-column>
        <el-table-column prop="orderSn" label="订单编号" width="190"> </el-table-column>
        <el-table-column prop="tokenName" label="数字通证名称" width="120"> </el-table-column>
        <el-table-column prop="type" label="类型">
          <template slot-scope="scope">
            <div>
              {{ scope.row.type == 1 ? '买入委托' : '卖出委托' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="真实委托量"> </el-table-column>
        <el-table-column prop="residueNum" label="剩余数量"> </el-table-column>
        <el-table-column prop="price" label="委托单价"> </el-table-column>
        <el-table-column prop="totalPrice" label="委托总价"> </el-table-column>
        <el-table-column prop="createTime" label="计划时间"> </el-table-column>
        <!-- <el-table-column prop="userTel" label="手机号"> </el-table-column> -->
        <!-- <el-table-column prop="userName" label="姓名"> </el-table-column> -->
        <el-table-column prop="finish" label="状态">
          <template slot-scope="scope">
            <div>
              {{ scope.row.finish == 0 ? '未成交' : scope.row.finish == 1 ? '成交' : scope.row.finish == 2 ? '部分成交' : '撤销' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div v-show="scope.row.finish == 0 || scope.row.finish == 2">
              <el-button type="primary" @click="chexiao(scope.$index, scope.row.id)">撤销</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.currentPage"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="form.pageNumber"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        certificateId: null, // 通证ID
        currentPage: 0, // 当前页
        pageSize: 10, // 分页打小
        userId: null, // 电话
        finish: null, // 状态ID
        type: 1 // 类型
      },
      selectBox: '',
      value: '',
      derivedForm: '',
      tabls: true,
      data: [],
      total: null,
      pageNumber: null,
      jsonFields: {
        UID: 'userId',
        订单编号: 'orderSn',
        数字通证名称: 'tokenName',
        类型: 'type',
        真实委托量: 'num',
        剩余数量: 'residueNum',
        委托单价: 'price',
        委托总价: 'totalPrice',
        计划时间: 'createTime',
        状态: 'finish'
      },
      options: '',
      finish: [
        { id: 0, name: '未成交' },
        { id: 1, name: '成交' },
        { id: 2, name: '部分成交' },
        { id: 3, name: '撤销' }
      ],
      statistical: '' // 委托统计
    }
  },
  created() {
    this.getData() // 获取数据
    this.getSelectBox() // 获取下拉框
  },
  methods: {
    // 监听时间
    pickerChange(e) {
      if (e) {
        this.form.startTime = e[0]
        this.form.endTime = e[1]
      } else {
        delete this.form.startTime
        delete this.form.endTime
      }
      this.form.currentPage = 1
      this.getData()
    },
    // 搜索
    onSearch() {
      this.getData()
    },
    // 点击table获取不同的数据
    storeage(e) {
      if (e == 1) {
        this.form.type = 1
        this.tabls = true
      } else {
        this.form.type = 2
        this.tabls = false
      }
      // this.form.finish = null;
      // this.form.certificateId = null;
      this.form.currentPage = 0
      this.form.pageSize = 10
      // this.form.tel = null;
      this.getData()
    },
    // 获取数据
    getData() {
      this.axios
        .get('/admin/entrust/findAllByFirmId', this.form)
        .then(res => {
          console.log(res, '5555555555555555555')
          this.data = res.data.data.page.records
          this.total = res.data.data.page.total

          this.form.currentPage = res.data.data.page.current

          this.form.pageSize = 10
          this.statistical = res.data.data.count
        })
        .catch(err => {})
    },

    // 获取所有需要导出的数据
    exportData() {
      this.axios.get('/admin/entrust/getAll', this.form).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          window.open(`http://47.109.88.30:9071/${res.data.data}`)
        } else {
          console('2222')
        }
      })
      // this.axios.get('/admin/userWater/getTime', {}).then(res => {
      //   if (res.data.code == -1) {
      //     this.axios.get('/admin/entrust/getAll', this.form).then(res => {
      //       console.log(res)
      //       if (res.data.code == 200) {
      //         window.open(`http://47.109.88.30:9071/${res.data.data}`)
      //       } else {
      //         console('2222')
      //       }
      //     })

      //     // 注释这里的原因是后端说这个接口太耗资源，该用列表接口
      //     // this.axios.get('/admin/userWithdraw/withdrawExcel', {}).then(res => {
      //     // this.form.pageSize = 200
      //     // this.axios.get('/admin/entrust/findAllByFirmId', this.form).then(res => {
      //     //   console.log(res)
      //     //   // this.derivedForm = res.data.data
      //     //   this.form.pageSize = 10
      //     //   this.derivedForm = res.data.data.page.records
      //     //   this.derivedForm.forEach(item => {
      //     //     item.finish = item.finish == 0 ? '未成交' : item.finish == 1 ? '成交' : item.finish == 2 ? '部分成交' : '撤销'
      //     //     item.type = item.type == 1 ? '买入委托' : '卖出委托'
      //     //   })
      //     // })
      //   } else {
      //     this.$message.error('本时段无法生成表格')
      //   }
      // })
    },

    // 获取下拉框
    getSelectBox() {
      this.axios.get('/admin/certificate/getDropDownBox', {}).then(res => {
        this.selectBox = res.data.data
      })
    },

    // 撤销
    chexiao(index, id) {
      // 调用弹窗 提示用户是否确认取消
      this.$confirm('确定撤销委托吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // this.undo();
          // 判断用户点击的是委托记录还是计划委托记录
          if (this.tabls) {
            // 委托记录
            this.axios.get('/admin/entrust/updateFinish', { id }).then(res => {
              if (res.data.code == 200) {
                this.getData()
                this.$message({
                  type: 'success',
                  message: res.data.mesg
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.data
                })
              }
            })
          } else {
            // 计划委托记录
            this.axios.get('/admin/entrust/updateFinish', { id }).then(res => {
              if (res.data.code == 200) {
                this.getData()
                this.$message({
                  type: 'success',
                  message: res.data.mesg
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.data
                })
              }
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消撤销'
          })
        })
    },
    // 分页
    handleSizeChange(val) {
      this.pageNumber = val
      if (this.tabls) {
        this.axios
          .get('/admin/entrust/findAllByFirmId', {
            type: 1,
            pageNumber: this.currentPage,
            pageSize: this.pageNumber,
            certificateId: this.form.certificateId,
            userId: this.form.userId,
            finish: this.form.finish
          })
          .then(res => {
            this.data = res.data.data.page.records
          })
      } else {
        this.axios
          .get('/admin/entrust/findAllByFirmId', {
            type: 2,
            pageNumber: this.currentPage,
            pageSize: this.pageNumber,
            certificateId: this.form.certificateId,
            userId: this.form.userId,
            finish: this.form.finish
          })
          .then(res => {
            this.data = res.data.data.page.records
          })
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val
      if (this.tabls) {
        this.axios
          .get('/admin/entrust/findAllByFirmId', {
            type: 1,
            currentPage: this.currentPage,
            pageSize: this.pageNumber,
            certificateId: this.form.certificateId,
            userId: this.form.userId,
            finish: this.form.finish
          })
          .then(res => {
            console.log(res)
            this.data = res.data.data.page.records
          })
      } else {
        this.axios
          .get('/admin/entrust/findAllByFirmId', {
            type: 2,
            currentPage: this.currentPage,
            pageSize: this.pageNumber,
            certificateId: this.form.certificateId,
            userId: this.form.userId,
            finish: this.form.finish
          })
          .then(res => {
            this.data = res.data.data.page.records
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// 导出样式
.export {
  color: #fff;
  background-color: #409eff;
  width: 90px;
  min-width: 90px;
  height: 40px;
  margin-top: 40px;
  margin-left: 15px;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
}
.export:hover {
  cursor: pointer;
}
.formFtest {
  display: flex;
}
.form3 {
  display: flex;
}
.form {
  margin-bottom: 30px;
  div {
    margin-right: 3px;
    span {
      //   font-weight: bold;
      font-size: 14px;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
}
.pagination {
  padding: 60px 0 0 100px;
}
.text {
  color: #848484;
  margin: 0 20px;
  span {
    margin-left: 10px;
    color: red;
  }
}
.statistical {
  span {
    margin: 0 20px;
    font-size: 14px;
    color: #848484;
  }
}
</style>
